<template>
<div class="six-bad-habit-container" id="js_six-bad-habit_container">
	<article class="six-bad-habit">
		<h1 class="six-bad-habit__header">
			<img src="@/assets/img/six-bad-habit/title01.png" alt="自律神経に負担をかける6つの習慣">
		</h1>

		<!-- 習慣 -->
		<section class="six-bad-habit__list--wrapper">
			<h2 class="six-bad-habit__list--title">イラストをタッチして、<br>悪い習慣を確認しよう！</h2>
			<ul class="six-bad-habit__list">
				<li class="six-bad-habit__item" @click="clickModalLink(3, $event)">
					<h3 class="six-bad-habit__item--title">スマホ・パソコンの見すぎ</h3>
					<p class="six-bad-habit__item--img"><i class="sprite_six-bad-habit img03_s" /></p>
				</li>
				<li class="six-bad-habit__item" @click="clickModalLink(4, $event)">
					<h3 class="six-bad-habit__item--title">座りっぱなしで運動不足</h3>
					<p class="six-bad-habit__item--img"><i class="sprite_six-bad-habit img04_s" /></p>
				</li>
				<li class="six-bad-habit__item" @click="clickModalLink(2, $event)">
					<h3 class="six-bad-habit__item--title">昼夜逆転の生活</h3>
					<p class="six-bad-habit__item--img"><i class="sprite_six-bad-habit img02_s" /></p>
				</li>
				<li class="six-bad-habit__item" @click="clickModalLink(5, $event)">
					<h3 class="six-bad-habit__item--title">完璧主義でがんばりすぎる</h3>
					<p class="six-bad-habit__item--img"><i class="sprite_six-bad-habit img05_s" /></p>
				</li>
				<li class="six-bad-habit__item" @click="clickModalLink(1, $event)">
					<h3 class="six-bad-habit__item--title">自己流の筋トレ</h3>
					<p class="six-bad-habit__item--img"><i class="sprite_six-bad-habit img01_s" /></p>
				</li>
				<li class="six-bad-habit__item" @click="clickModalLink(6, $event)">
					<h3 class="six-bad-habit__item--title">おやつを毎日食べすぎる</h3>
					<p class="six-bad-habit__item--img"><i class="sprite_six-bad-habit img06_s" /></p>
				</li>
			</ul>
		</section>
		<!-- /習慣 -->

		<!-- 監修者 -->
		<section class="six-bad-habit__supervisor">
			<h2 class="six-bad-habit__supervisor--img"><img src="@/assets/img/six-bad-habit/img01.png" alt="面白いほどわかる 自律神経の新常識"></h2>
			<div class="six-bad-habit__supervisor--inner">
				<p class="six-bad-habit__supervisor--title"><span class="is-small">TJ MOOK</span><br>面白いほどわかる<br>自律神経の新常識</p>
				<p class="six-bad-habit__supervisor--text">著者：久手堅 司（監修）<br>出版社：宝島社</p>
			</div>
		</section>
		<!-- /監修者 -->

		<!-- バランスチェック -->
		<section class="six-bad-habit__balancecheck">
			<h2 class="six-bad-habit__balancecheck--title">悪い習慣を確認したら、自律神経のバランスが乱れていないかチェックしてみましょう。</h2>
			<div class="six-bad-habit__balancecheck--inner">
				<h3 class="six-bad-habit__balancecheck--subtitle"><i class="sprite_six-bad-habit title01" title="【医師監修】自律神経のバランスチェック" /></h3>
				<a href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=balancecheckAutonomicNerves` } })" class="six-bad-habit__balancecheck--link">チェックをはじめる</a>
			</div>
		</section>
		<!-- /バランスチェック -->

		<!-- アウトロ -->
		<section class="six-bad-habit__outro">
			<h2 class="six-bad-habit__outro--title"><i class="sprite_six-bad-habit title02" title="頭痛ーるで不調になりやすい日を知ろう！" /></h2>
			<p class="six-bad-habit__outro--text">自律神経が乱れると、気圧や温度の変化で体調不良になる「気象病」の症状も出やすくなります。頭痛ーるは、不調の原因となる「気圧の変化」をお知らせします。</p>
			<div class="growthhack-f__caution">
				<p class="growthhack-f__caution--right">低気圧で体調崩すかも。早めに用事を済ませよう！</p>
				<p class="growthhack-f__caution--left">痛みが出る前に薬を準備しておこう！</p>
			</div>
			<div class="growthhack-f__with-zutool">
				<p class="growthhack-f__with-zutool--title">
					with <span class="is-large">頭痛ーる</span>で<br>気圧と上手に付き合って<br>快適な毎日へ♪
				</p>
				<i class="growthhack-f__with-zutool--img sprite_six-bad-habit with-zutool" />
			</div>
			<div class="about-rightnow__login">
				<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="about-rightnow__login--button" id="trackingSixBadHabitAppDownloadBtn01_android">便利なアプリをダウンロード<span>スゴ得ユーザーは使い放題！</span></a>
				<a v-else @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="about-rightnow__login--button" id="trackingSixBadHabitAppDownloadBtn01_ios">便利なアプリをダウンロード<span>スゴ得ユーザーは使い放題！</span></a>
			</div>
			<div class="six-bad-habit__outro--box">
				<a @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=lp`, _lpType: 'lpautonomicnerves2021' } })" class="six-bad-habit__outro--back-balancecheck-button">自律神経の<br>バランスチェック特集にもどる</a>
				<a @click="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId } })" class="six-bad-habit__outro--back-top-button about-rightnow__login--button">TOPにもどる</a>
			</div>
		</section>
		<!-- /アウトロ -->
	</article>

	<!-- モーダル -->
	<v-ons-modal :visible="isVisibleModal" animation="fade" animationOptions="{duration: 0.2, timing: 'ease-in'}" class="six-bad-habit__modal">
		<aside class="six-bad-habit__modal--inner">
			<div v-show="modalType === 1"  class="six-bad-habit__modal--body">
				<p class="six-bad-habit__modal--title">自己流の筋トレ</p>
				<i :class="['sprite_six-bad-habit', `img0${modalType}`]" />
				<p class="six-bad-habit__modal--text">ダンベルなどの器具を使うトレーニングは、持ち方ひとつで手首や肩を痛める可能性も。最終的には<span>骨格をゆがめる原因</span>になり、<span>自律神経に負担</span>がかかります。</p>
			</div>
			<div v-show="modalType === 2"  class="six-bad-habit__modal--body">
				<p class="six-bad-habit__modal--title">昼夜逆転の生活</p>
				<i :class="['sprite_six-bad-habit', `img0${modalType}`]" />
				<p class="six-bad-habit__modal--text">自律神経は、<span>日中は交感神経優位、夜間は副交感神経優位になる生活を好みます。</span>昼と夜が逆転すると、自律神経も逆転して動くので、必然的に負担になります。</p>
			</div>
			<div v-show="modalType === 3"  class="six-bad-habit__modal--body">
				<p class="six-bad-habit__modal--title">スマホ・パソコンの見すぎ</p>
				<i :class="['sprite_six-bad-habit', `img0${modalType}`]" />
				<p class="six-bad-habit__modal--text">スマホやパソコンを長時間見ていると、<span>姿勢が悪くなり、骨格がゆがんできます。</span>脳や視神経も疲労し、慢性的なストレスとして蓄積され、自律神経にも影響があります。</p>
			</div>
			<div v-show="modalType === 4"  class="six-bad-habit__modal--body">
				<p class="six-bad-habit__modal--title">座りっぱなしで運動不足</p>
				<i :class="['sprite_six-bad-habit', `img0${modalType}`]" />
				<p class="six-bad-habit__modal--text">自律神経のバランスを崩しやすいのは、体を使う職業の人よりもデスクワークの人。<span>体を適度に動かさないと血流が滞り、交感神経ばかりが働いて</span>しまいます。</p>
			</div>
			<div v-show="modalType === 5"  class="six-bad-habit__modal--body">
				<p class="six-bad-habit__modal--title">完璧主義でがんばりすぎる</p>
				<i :class="['sprite_six-bad-habit', `img0${modalType}`]" />
				<p class="six-bad-habit__modal--text">がんばりすぎて、ゆとりのない生活は、ストレスがたまります。<span>ストレス過剰な状態になると、交換神経が高まったまま</span>になり、体がこわばってさらに疲労感が増します。</p>
			</div>
			<div v-show="modalType === 6"  class="six-bad-habit__modal--body">
				<p class="six-bad-habit__modal--title">おやつを毎日食べすぎる</p>
				<i :class="['sprite_six-bad-habit', `img0${modalType}`]" />
				<p class="six-bad-habit__modal--text">おやつは砂糖や小麦粉など、糖質が多いものがほとんどです。<span>糖質の摂りすぎは血糖値の急上昇を招き、自律神経を乱します。</span></p>
			</div>
			<div class="six-bad-habit__modal--button">
				<a @click="clickModalLink(0, $event)">
					<ons-icon icon="md-close" />表示を閉じる
				</a>
			</div>
		</aside>
	</v-ons-modal>
	<!-- ・モーダル -->
</div>
</template>

<script>
import cmnConst from '@/assets/js/constant.js'

// Vuex
import { mapGetters } from 'vuex'

export default {
	name: 'SixBadHabit',
	components: {
	},
	data () {
		return {
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID,
			backendUrl: cmnConst.BACKEND_URL,
			isVisibleModal: false,
			modalType: 0
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['isAndroid'])
	},
	mounted () {
		// コンテンツの高さをemitする処理
		// CHANGED: コンテンツの高さを確保する処理の見直しのためコメントアウト
		// this.$nextTick(() => {
		// 	setTimeout(() => {
		// 		const domContainer = document.getElementById('js_six-bad-habit_container')
		// 		if (domContainer) this.$emit('containerHeight', domContainer.clientHeight)
		// 	}, 1000)
		// })
	},
	beforeDestroy () {
	},
	methods: {
		/**
		 * モーダルのリンククリックイベント
		 */
		clickModalLink (value, event) {
			event.preventDefault()
			this.isVisibleModal = value > 0
			if (value > 0) this.modalType = value
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/variable";

// NOTE: このページ限定の変数（デザインシステム外のため他での利用禁止）
$spacing-12: 0.75rem;
$spacing-14: 0.875rem;
$spacing-15: 0.937rem;
$spacing-18: 1.125rem;
$spacing-22: 1.375rem;
$spacing-24: 1.5rem;
$spacing-26: 1.625rem;
$spacing-30: 1.875rem;
$spacing-40: 2.5rem;
$spacing-42: 2.625rem;
$font-size-11: 0.687rem;
$font-size-15: 0.937rem;
$font-size-19: 1.187rem;
$six-bad-habit-primary: #FC7053;
$six-bad-habit-secondary: #0080D5;
$balancecheck-primary: #48B8DB;
$balancecheck-back-background: #34ABD1;
$balancecheck-back-border: #2091B5;
$line-height-13: 1.3;
$line-height-1375: 1.375;

.six-bad-habit {
	font-family: $lp-family-primary;
	max-width: 375px;
	margin: 0 auto;
	padding: 0;

	&__header { padding: $spacing-10 $spacing-15 0; }

	&__list {
		margin: $spacing-10 $spacing-14 0;
		padding: 0 0 $spacing-22;
		border-bottom: 1px solid $line-primary;
		list-style: none;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		&--title {
			margin: $spacing-8 auto 0;
			color: $six-bad-habit-primary;
			text-align: center;
			font-weight: bold;
			font-size: $font-size-16;
			line-height: $line-height-1375;
		}
	}

	&__item {
		width: 48.415%;
		min-height: 96px;
		margin-top: $spacing-10;
		box-sizing: border-box;
		border: 1px solid $six-bad-habit-secondary;
		border-radius: 6px;

		&:nth-of-type(1),
		&:nth-of-type(2) { margin-top: 0; }

		&--title {
			margin: $spacing-8 0 $spacing-6;
			font-size: $font-size-11;
			font-weight: bold;
			text-align: center;
			line-height: $line-height-1;
		}
		&:nth-of-type(4) .six-bad-habit__item--title { margin: $spacing-8 0 $spacing-2; }

		&--img {
			margin: 0 auto;

			& > i { margin: 0 auto; }
		}
	}

	&__supervisor { margin: $spacing-24 auto 0; }

	&__balancecheck {
		margin: $spacing-30 0 0;
		padding: 0 $spacing-14;

		&--title {
			margin: 0;
			padding: 0;
			font-family: $lp-family-primary;
			font-size: $font-size-15;
			font-weight: normal;
		}

		&--inner {
			margin-top: $spacing-20;
			padding: $spacing-12 0 $spacing-18;
			border: 2px solid $six-bad-habit-secondary;
			border-radius: 10px;
		}

		&--subtitle {
			margin: 0;
			text-align: center;
			font-size: $font-size-20;
			line-height: $line-height-1;

			@media (max-width: (360px)) { font-size: $font-size-18; }

			& > i {
				position: relative;
				margin: 0 auto;
				top: 0;
				left: -2px;

				@media (max-width: (374px)) {
					left: 50%;
					transform: scale(.853) translateX(-50%);
					transform-origin: 0 0;
				}
			}
		}

		&--link {
			display: block;
			width: 87.464%;
			margin: $spacing-20 auto 0;
			padding: $spacing-12 0;
			background-color: $balancecheck-primary;
			border-radius: 6px;
			text-align: center;
			color: $text-seconday;
			font-size:  $font-size-18;
			text-decoration: none;
			line-height: $line-height-1;
		}
	}

	&__outro {
		margin: $spacing-42 0 0;

		&--title {
			margin: 0;

			& > i {
				margin: 0 auto;

				@media (max-width: (360px)) {
					position: relative;
					left: 50%;
					transform: scale(.853) translateX(-50%);
					transform-origin: 0 0;
				}
			}
		}

		&--text {
			margin: $spacing-10 0 0 0;
			padding: 0 $spacing-14;
			line-height: $line-height;
			font-family: $lp-family-primary;
			font-size: $font-size-15;
		}

		.growthhack-f__caution { margin: $spacing-12 auto 0; }

		.about-rightnow__login { padding: 0; }

		&--box {
			width: 92.5334%;
			margin: $spacing-30 auto 0;
			border-top: 1px solid $line-primary;

			& > a {
				display: block;
				width: 86.4553%;
				margin: $spacing-40 auto 0;
			}
		}

		&--back-balancecheck-button {
			padding: $spacing-10 0;
			box-sizing: border-box;
			background: $balancecheck-back-background;
			box-shadow: 0 3px $balancecheck-back-border;
			border-radius: 6px;
			text-align: center;
			color: $text-seconday;
			font-weight: bold;
			font-size: $font-size-19;
			line-height: $line-height-13;
			pointer-events: auto;

			@media (max-width: (374px)) { font-size: $font-size-16; }
			@media (max-width: (330px)) { font-size: $font-size-14; }
		}

		&--back-top-button { font-weight: normal; }
	}

	&__modal {
		position: fixed;
		font-family: $lp-family-primary;

		/deep/ .modal__content {
			padding-top: 72px;
			vertical-align: top;
		}

		&--inner {
			width: 300px;
			margin: 0 auto;
			background-color: $background-primary;
		}

		&--body {
			position: relative;
			padding: $spacing-26 $spacing-20 0;

			& > i {
				margin: $spacing-24 auto 0;
			}
		}

		&--title {
			width: 235px;
			margin: 0 auto;
			padding: 0 0 $spacing-20;
			border-bottom: 1px dashed $line-primary;
			color: $six-bad-habit-primary;
			text-align: center;
			font-weight: bold;
			font-size: $font-size-20;
			line-height: $line-height-1;
			white-space: nowrap;
		}

		&--text {
			color: $text-primary;
			margin: $spacing-10 0 0;
			text-align: left;
			font-size: $font-size-15;
			line-height: $line-height;

			& > span { color: $six-bad-habit-primary; }
		}

		&--button {
			display: table;
			width: 300px;
			height: 43px;
			margin: $spacing-8 0 0;
			background: $background-primary;
			box-sizing: border-box;

			& > a {
				display: table-cell;
				width: 100%;
				vertical-align: middle;
				text-align: center;
				color: $text-primary;
				font-size: $font-size-12;

				ons-icon {
					font-size: $font-size-16;
					vertical-align: middle;
					margin-right: $spacing-10;
				}
			}
		}
	}
}

// スプライト
.sprite_six-bad-habit {
	background-image: url(~@/assets/img/six-bad-habit/sprite.png);
	background-size: (704px / 2) (3303px / 2);
	background-repeat: no-repeat;
	display: block;

	&.img01 {
		width: (408px / 2);
		height: (310px / 2);
		background-position: (-5px  / 2) (-5px / 2);
	}

	&.img01_s {
		width: (164px / 2);
		height: (124px / 2);
		background-position: (-5px  / 2) (-325px / 2);
	}

	&.img02 {
		width: (470px / 2);
		height: (316px / 2);
		background-position: (-5px  / 2) (-459px / 2);
	}

	&.img02_s {
		width: (208px / 2);
		height: (132px / 2);
		background-position: (-5px  / 2) (-785px / 2);
	}

	&.img03 {
		width: (470px / 2);
		height: (316px / 2);
		background-position: (-5px  / 2) (-927px / 2);
	}

	&.img03_s {
		width: (160px / 2);
		height: (126px / 2);
		background-position: (-5px  / 2) (-1253px / 2);
	}

	&.img04 {
		width: (470px / 2);
		height: (316px / 2);
		background-position: (-5px  / 2) (-1389px / 2);
	}

	&.img04_s {
		width: (260px / 2);
		height: (140px / 2);
		background-position: (-5px  / 2) (-1715px / 2);
	}

	&.img05 {
		width: (470px / 2);
		height: (316px / 2);
		background-position: (-5px  / 2) (-1865px / 2);
	}

	&.img05_s {
		width: (230px / 2);
		height: (126px / 2);
		background-position: (-5px  / 2) (-2191px / 2);
	}

	&.img06 {
		width: (474px / 2);
		height: (288px / 2);
		background-position: (-5px  / 2) (-2327px / 2);
	}

	&.img06_s {
		width: (188px / 2);
		height: (114px / 2);
		background-position: (-5px  / 2) (-2625px / 2);
	}

	&.title01 {
		width: (694px / 2);
		height: (151px / 2);
		background-position: (-5px  / 2) (-2749px / 2);
	}

	&.title02 {
		width: (694px / 2);
		height: (112px / 2);
		background-position: (-5px  / 2) (-2910px / 2);
	}

	&.with-zutool {
		width: (224px / 2);
		height: (266px / 2);
		background-position: (-5px  / 2) (-3032px / 2);
	}
}

// トランジション
.fade-enter-active, .fade-leave-active {
	transition: opacity .2s;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}
</style>
